export const langSwitcherNames = {
  EN: 'International',
  FR: 'France',
  PL: 'Polski',
  DE: 'Deutsch',
};

export const downloadText = {
  EN: 'Download Product Card',
  FR: 'Télécharger la fiche produit',
  PL: 'Pobierz kartę produktu',
  DE: 'Produktkarte herunterladen',
};

export const downloadFabricText = {
  EN: 'Download Fabric Specification',
  FR: 'Télécharger les caractéristiques du tissu',
  PL: 'Pobierz specyfikację tkaniny',
  DE: 'Stoffspezifikationen herunterladen',
};

export const featuresText = {
  EN: 'FEATURES',
  FR: 'CARACTÉRISTIQUES',
  PL: 'CECHY',
  DE: 'EIGENSCHAFTEN',
};

export const textureText = {
  EN: 'TEXTURE',
  FR: 'TEXTURES',
  PL: 'TEKSTURA',
  DE: 'STRUKTUR',
};

export const careInstructionsText = {
  EN: 'CARE INSTRUCTIONS',
  FR: 'CONSEILS D’ENTRETIEN',
  PL: 'INSTRUKCJE PIELĘGNACJI',
  DE: 'PFLEGEANLEITUNGEN',
};

export const upholsterysText = {
  EN: 'UPHOLSTERY',
  FR: 'TISSUS',
  PL: 'RODZAJ WYKOŃCZENIA',
  DE: 'POLSTERUNG',
};

export const confortText = {
  EN: 'COMFORTS',
  FR: 'REMBOURRAGES',
  PL: 'KOMFORT',
  DE: 'SITZKOMFORT',
};

export const coversText = {
  EN: 'COVERS',
  FR: 'HOUSSES',
  PL: 'POSZYCIA',
  DE: 'BEZÜGE',
};

export const dimensionsText = {
  EN: 'Dimensions',
  FR: 'Dimensions',
  PL: 'Wymiary',
  DE: 'Maße',
};

export const legsText = {
  EN: 'Legs',
  FR: 'Piètement',
  PL: 'Nogi',
  DE: 'Füße',
};

export const accessoriesText = {
  EN: 'Accessories',
  FR: 'Accessoires',
  PL: 'Akcesoria',
  DE: 'Accessoires',
};

export const armrestText = {
  EN: 'Armrest',
  FR: 'Accoudoirs',
  PL: 'Podłokietniki',
  DE: 'Armlehnen',
};

export const faqTitle = {
  EN: 'FAQ',
  FR: 'FAQ',
  PL: 'FAQ',
  DE: 'FAQ',
};

export const assemblyTitle = {
  EN: 'Assembly instructions',
  FR: 'Notices de montage',
  PL: 'Instrukcje montażu',
  DE: 'Montageanleitungen',
};

export const searchPlaceholder = {
  EN: 'Search',
  FR: 'Rechercher',
  PL: 'Szukaj',
  DE: 'Suchen',
};

export const exploreButton = {
  EN: 'EXPLORE',
  FR: 'EXPLORER',
  PL: 'ZOBACZ',
  DE: 'ENTDECKEN',
};

export const noResultTitle = {
  EN: 'You have No Favorites yet',
  FR: 'Il n’y a pas encore d’articles dans vos Favoris.',
  PL: 'Nie masz jeszcze ulubionych produktów',
  DE: 'Sie haben noch keine Favoriten',
};

export const noResultMessage = {
  EN: `Favorized products and covers will show up here.<br/>
    Great! Take me to <a href='/products/'>products</a> or <a href='/material/'>covers</a>.`,
  FR: `Les produits et couvertures favoris s'afficheront ici.<br/>
    Super! Emmenez-moi <a href='/products/'>aux produits</a> ou <a href='/material/'>aux couvertures</a>.`,
  PL: `Ulubione produkty i poszycia pojawią się tutaj.<br/>
    Świetnie! Zabierz mnie <a href='/products/'>do produktów</a> lub <a href='/material/'>do pokryć</a>.`,
  DE: `Favorisierte Produkte und Bezüge werden hier angezeigt.<br/>
    Super! Bringen Sie mich zu <a href='/products/'>Produkten</a> oder <a href='/material/'>Bezügen</a>.`,
};

export const collectionsTitle = {
  EN: 'Collections',
  FR: 'Collections',
  PL: 'Kolekcje',
  DE: 'Kollektionen',
};
export const checkOurMostPopular = {
  EN: 'Check our most popular products',
  FR: 'Découvrez nos produits les plus populaires',
  PL: 'Sprawdź nasze najpopularniejsze produkty',
  DE: 'Entdecken Sie unsere beliebtesten Produkte',
};
export const sofasTitle = {
  EN: 'Sofas',
  FR: 'Canapés',
  PL: 'Kanapy',
  DE: 'Sofas',
};
export const armchairsTitle = {
  EN: 'Armchairs',
  FR: 'Fauteuils',
  PL: 'Fotele',
  DE: 'Sessel',
};
export const coffeeTablesTitle = {
  EN: 'Coffee Tables',
  FR: 'Tables basses',
  PL: 'Stoliki kawowe',
  DE: 'Couchtische',
};
export const diningChairsTitle = {
  EN: 'Dining Chairs',
  FR: 'Chaises de salle à manger',
  PL: 'Krzesła obiadowe',
  DE: 'Esstische',
};
export const footstoolsTitle = {
  EN: 'Footstools',
  FR: 'Repose-pieds',
  PL: 'Podnóżki',
  DE: 'Hocker',
};
export const outdoorFurnituresTitle = {
  EN: 'Outdoor Furniture',
  FR: 'Mobilier d’extérieur',
  PL: 'Meble ogrodowe',
  DE: 'Outdoor-Möbel',
};
export const materialsTitle = {
  EN: 'Covers',
  FR: 'Housses',
  PL: 'Poszycia',
  DE: 'Bezüge',
};
export const meterialsAlterTitle = {
  EN: 'Materials',
  FR: 'Tissus',
  PL: 'Materiały',
  DE: 'Materialien',
};
export const colorsTitle = {
  EN: 'Colors of covers',
  FR: 'Couleurs de housses',
  PL: 'Kolory pokryć',
  DE: 'Farben der Bezüge',
};
export const favouritesTitle = {
  EN: 'My Favourites',
  FR: 'Mes favoris',
  PL: 'Ulubione',
  DE: 'Meine Favoriten',
};

export const similarTitle = {
  EN: 'is a popular choice for these models',
  FR: 'est l’un des meubles les plus choisis parmi ces modèles',
  PL: 'jest popularnym wyborem dla tych modeli',
  DE: 'Populäre Wahl bei diesen Modellen',
};

export const coversTitle = {
  EN: 'Similar Covers',
  FR: 'Housses similaires',
  PL: 'Podobne poszycia',
  DE: 'Vergleichbare Bezüge',
};

export const checkOtherCollections = {
  EN: 'Check our other collections',
  FR: 'Découvrez nos autres collections',
  PL: 'Sprawdź nasze inne kolekcje',
  DE: 'Entdecken Sie unsere anderen Kollektionen',
};

export const collectionSimilarTitle = {
  EN: 'Similar products',
  FR: 'Produits similaires',
  PL: 'Podobne produkty',
  DE: 'Vergleichbare Produkte',
};

export const collectionCoversTitle = {
  EN: 'Recommended covers for ',
  FR: 'Tissus recommandés pour le ',
  PL: 'Polecane poszycia dla ',
  DE: 'Empfohlene Bezüge für ',
};
export const allProductsTitle = {
  EN: 'Discover our collections',
  FR: 'Découvrez nos collections',
  PL: 'Odkryj nasze kolekcje',
  DE: 'Entdecken Sie unsere Kollektionen',
};
export const newArrivalsTitle = {
  EN: 'New Arrivals',
  FR: 'Nouveautés',
  PL: 'Nowości',
  DE: 'Neuheiten',
};

export const manageCookies = {
  EN: 'MANAGE COOKIES',
  FR: 'GESTION DES COOKIES',
  PL: 'ZARZĄDZAJ COOKIES',
  DE: 'COOKIES VERWALTEN',
};

export const left = {
  EN: [
    { name: 'ABOUT', url: '/about-sits/' },
    { name: 'SUSTAINABILITY', url: '/sustainability/' },
    { name: 'LEGAL', url: '/legal/' },
    { name: 'PUBLIC INFORMATION', url: '/public-information/' },
  ],
  FR: [
    { name: 'À PROPOS', url: '/fr/une-fabrication-traditionnelle-artisanale/' },
    { name: 'DURABILITÉ', url: '/fr/durabilite/' },
    { name: 'MENTIONS LÉGALES', url: '/fr/juridique/' },
    { name: 'PUBLIC INFORMATION', url: '/public-information/' },
  ],
  PL: [
    { name: 'O SITS', url: '/pl/o-nas/' },
    { name: 'ZRÓWNOWAŻONY ROZWÓJ', url: '/pl/zrownowazony-rozwoj/' },
    { name: 'POLITYKA PRYWATNOŚCI', url: '/pl/polityka-prywatnosci/' },
    { name: 'INFORMACJE PUBLICZNE', url: '/public-information/' },
  ],
  DE: [
    { name: 'ÜBER UNS', url: '/de/ueber/' },
    { name: 'NACHHALTIGKEIT', url: '/de/nachhaltigkeit/' },
    { name: 'RECHTLICHES', url: '/de/rechtliches/' },
    { name: 'ÖFFENTLICHE INFORMATIONEN', url: '/public-information/' },
  ],
};

export const right = {
  EN: [
    { name: 'WHERE TO BUY', url: '/where-to-buy/' },
    { name: 'SALES REPRESENTATIVE', url: '/sales-representative/' },
    { name: 'MEET US', url: '/exhibitions/' },
    { name: 'CONTACT', url: '/contact/' },
  ],
  FR: [
    { name: 'TROUVER UN POINT DE VENTE', url: '/fr/trouver-un-point-de-vente/' },
    { name: 'REPRÉSENTANT COMMERCIAL', url: '/fr/representant-commercial/' },
    { name: 'DES EXPOSITIONS', url: '/fr/des-expositions/' },
    { name: 'NOUS CONTACTER', url: '/fr/nous-contacter/' },
  ],
  PL: [
    { name: 'SALONY SPRZEDAŻY', url: '/pl/salony-sprzedazy/' },
    { name: 'PRZEDSTAWICIELE HANDLOWI', url: '/pl/przedstawiciel-handlowy/' },
    { name: 'SPOTKAJ NAS', url: '/pl/spotkaj-nas/' },
    { name: 'KONTAKT', url: '/pl/kontakt/' },
  ],
  DE: [
    { name: 'HÄNDLER FINDEN', url: '/de/haendler-finden/' },
    { name: 'Vertretungen', url: '/de/vertretungen/' },
    { name: 'TREFFEN SIE UNS', url: '/de/ausstellungen/' },
    { name: 'KONTAKT', url: '/de/kontakt/' },
  ],
};

export const rightCareer = {
  EN: { name: 'CAREER', url: '/career/' },
  FR: { name: 'CARRIÈRE', url: '/fr/carriere/' },
  PL: { name: 'KARIERA', url: '/pl/kariera/' },
  DE: { name: 'KARRIERE', url: '/de/karriere/' },
};

export const linksLeft = {
  EN: [
    { name: 'Best Seller', url: '/best-sellers/' },
    { name: 'New Arrivals', url: '/new-arrivals/' },
    { name: 'All products', url: '/products/' },
    { name: 'Sofas', url: '/products/sofas/' },
    { name: 'Sofa beds', url: '/products/sofas/?type=Sofa-beds' },
    { name: 'Armchairs', url: '/products/armchairs/' },
    { name: 'Dining Chairs', url: '/products/dining-chairs/' },
    { name: 'Footstools', url: '/products/footstools/' },
    { name: 'Coffee Tables', url: '/products/coffee-tables/' },
    { name: 'Outdoor Furniture', url: '/products/outdoor-furniture/' },
    // { name: 'Product configurator', url: 'https://sitsconnect.pl/configurator' },
    { name: 'Covers', url: '/material/' },
    { name: 'My Favourites', icon: 'hearth', url: '/favourites/' },
  ],
  FR: [
    { name: 'Meilleures ventes', url: '/fr/incontournables/' },
    { name: 'Nouveautés', url: '/fr/nouveautes/' },
    { name: 'Tous les produits', url: '/fr/produits/' },
    { name: 'Canapés', url: '/fr/produits/canapes/' },
    { name: 'Canapés-lits', url: '/products/sofas/?type=Sofa-beds' },
    { name: 'Fauteuils', url: '/fr/produits/fauteuils/' },
    { name: 'Chaises de salle à manger', url: '/fr/produits/chaises-de-salle-a-manger/' },
    { name: 'Repose-pieds', url: '/fr/produits/repose-pieds/' },
    { name: 'Tables basses', url: '/fr/produits/tables-basses/' },
    { name: 'Mobilier d’extérieur', url: '/fr/produits/mobilier-dexterieur/' },
    // { name: 'Configurateur de produit', url: 'https://sitsconnect.pl/configurator' },
    { name: 'Housses', url: '/fr/materiaux/' },
    { name: 'Mes favoris', icon: 'hearth', url: '/fr/mes-favoris/' },
  ],
  PL: [
    { name: 'Bestsellery', url: '/pl/bestsellery/' },
    { name: 'Nowości', url: '/pl/nowosci/' },
    { name: 'Wszystkie produkty', url: '/pl/produkty/' },
    { name: 'Sofy', url: '/pl/produkty/sofy/' },
    { name: 'Narożniki', url: '/pl/produkty/sofy/?typ=Narożniki' },
    { name: 'Fotele', url: '/pl/produkty/fotele/' },
    { name: 'Krzesła do jadalni', url: '/pl/produkty/krzesla-do-jadalni/' },
    { name: 'Podnóżki', url: '/pl/produkty/podnozki/' },
    { name: 'Stoliki kawowe', url: '/pl/produkty/stoliki-kawowe/' },
    { name: 'Meble ogrodowe', url: '/pl/produkty/meble-ogrodowe/' },
    // {name: 'Konfigurator produktu', url: 'https://sitsconnect.pl/configurator'},
    { name: 'Poszycia', url: '/pl/material/' },
    { name: 'Moje ulubione', icon: 'hearth', url: '/pl/moje-ulubione/' },
  ],
  DE: [
    { name: 'Bestseller', url: '/de/bestseller/' },
    { name: 'Neuheiten', url: '/de/neuheiten/' },
    { name: 'Alle Produkte', url: '/de/produkte/' },
    { name: 'Sofas', url: '/de/produkte/sofas/' },
    { name: 'Schlafsofas', url: '/de/produkte/sofas/?typ=Schlafsofas' },
    { name: 'Sessel', url: '/de/produkte/sessel/' },
    { name: 'Esszimmerstühle', url: '/de/produkte/esszimmerstuehle/' },
    { name: 'Hocker', url: '/de/produkte/hocker/' },
    { name: 'Couchtische', url: '/de/produkte/couchtische/' },
    { name: 'Outdoor-Möbel', url: '/de/produkte/outdoor-moebel/' },
    // {name: 'Produktkonfigurator', url: 'https://sitsconnect.pl/configurator'},
    { name: 'Bezüge', url: '/de/materialien/' },
    { name: 'Meine Favoriten', icon: 'hearth', url: '/de/meine-favoriten/' },
  ],
};

export const linksRight = {
  EN: [
    { name: 'About Sits', url: '/about-sits/' },
    { name: 'Sustainability', url: '/sustainability/' },
    { name: 'Furniture Care', url: '/furniture-care/' },
    { name: 'Catalogues', url: '/catalogues/' },
    { name: 'Virtual showroom', url: 'https://showroom.sits.eu/' },
    { name: 'Sales Representative', url: '/sales-representative/' },
    { name: 'Where to Buy', url: '/where-to-buy/' },
    { name: 'Dealer login', url: 'https://sitsconnect.pl/' },
    { name: 'Meet us', url: '/exhibitions/' },
    { name: 'Contact', url: '/contact/' },
  ],
  FR: [
    { name: 'À propos de Sits', url: '/fr/une-fabrication-traditionnelle-artisanale/' },
    { name: 'Durabilité', url: '/fr/durabilite/' },
    { name: 'Entretien des meubles', url: '/fr/entretien-des-meubles/' },
    { name: 'Catalogues', url: '/fr/catalogues/' },
    { name: 'Vitrine d’exposition virtuelle', url: 'https://showroom.sits.eu/' },
    { name: 'Représentant commercial', url: '/fr/representant-commercial/' },
    { name: 'Trouver un point de vente', url: '/fr/trouver-un-point-de-vente/' },
    { name: 'Connexion du revendeur', url: 'https://sitsconnect.pl/' },
    { name: 'Des expositions', url: '/fr/des-expositions/' },
    { name: 'Nous contacter', url: '/fr/nous-contacter/' },
  ],
  PL: [
    { name: 'O Sits', url: '/pl/o-nas/' },
    { name: 'Zrównoważony rozwój', url: '/pl/zrownowazony-rozwoj/' },
    { name: 'Pielęgnacja mebli', url: '/pl/pielegnacja-mebli/' },
    { name: 'Katalogi', url: '/pl/katalogi/' },
    { name: 'Wirtualny showroom', url: 'https://showroom.sits.eu/' },
    { name: 'Przedstawiciele handlowi', url: '/pl/przedstawiciel-handlowy/' },
    { name: 'Salony sprzedaży', url: '/pl/salony-sprzedazy/' },
    { name: 'Logowanie dealera', url: 'https://sitsconnect.pl/' },
    { name: 'Spotkaj nas', url: '/pl/spotkaj-nas/' },
    { name: 'Kontakt', url: '/pl/kontakt/' },
  ],
  DE: [
    { name: 'Über Sits', url: '/de/ueber/' },
    { name: 'Nachhaltigkeit', url: '/de/nachhaltigkeit/' },
    { name: 'Möbelpflege', url: '/de/moebelpflege/' },
    { name: 'Kataloge', url: '/de/kataloge/' },
    { name: 'Virtueller Showroom', url: 'https://showroom.sits.eu/' },
    { name: 'Vertretungen ', url: '/de/vertretungen/' },
    { name: 'Händler finden', url: '/de/haendler-finden/' },
    { name: 'Händler-Login', url: 'https://sitsconnect.pl/' },
    { name: 'Treffen Sie uns', url: '/de/ausstellungen/' },
    { name: 'Kontakt', url: '/de/kontakt/' },
  ],
};

export const linkRightCareer = {
  EN: { name: 'Career', url: '/career/' },
  FR: { name: 'Carrière', url: '/fr/carriere/' },
  PL: { name: 'Kariera', url: '/pl/kariera/' },
  DE: { name: 'Karriere', url: '/de/karriere/' },
};

export const furnitureTitle = {
  EN: 'FURNITURE',
  FR: 'MEUBLES',
  PL: 'MEBLE',
  DE: 'MÖBEL',
};

export const companyTitle = {
  EN: 'COMPANY',
  FR: 'ENTREPRISE',
  PL: 'POZNAJ NAS',
  DE: 'UNTERNEHMEN',
};

export const copySuccessed = {
  EN: 'Configuration copied successfully',
  FR: 'Configuration dupliquée avec succès',
  PL: 'Konfiguracja skopiowana pomyślnie',
  DE: 'Konfiguration erfolgreich kopiert',
};

export const materialTitle = {
  EN: 'Material:',
  FR: 'Matériau:',
  PL: 'Materiał:',
  DE: 'Material:',
};

export const tableTopTitle = {
  EN: 'Table top material:',
  FR: 'Matériau du plateau de la table:',
  PL: 'Materiał blatu:',
  DE: 'Material der Tischplatte:',
};

export const leatherTitle = {
  EN: 'Leather:',
  FR: 'Cuir:',
  PL: 'Skóra:',
  DE: 'Leder:',
};

export const fabricTitle = {
  EN: 'Fabric:',
  FR: 'Tissu:',
  PL: 'Materiał:',
  DE: 'Bezugsstoff:',
};

export const modelTitle = {
  EN: 'Model:',
  FR: 'Modèle:',
  PL: 'Model:',
  DE: 'Modell:',
};

export const legsTitle = {
  EN: 'Legs:',
  FR: 'Piètement:',
  PL: 'Nogi:',
  DE: 'Füße:',
};

export const legsMaterialTitle = {
  EN: 'Material of the legs:',
  FR: 'Matériau du piètement:',
  PL: 'Materiał nóg:',
  DE: 'Material der Füße:',
};

export const comfortTitle = {
  EN: 'Comfort:',
  FR: 'Garniture:',
  PL: 'Komfort:',
  DE: 'Sitzkomfort:',
};

export const coverTitle = {
  EN: 'Cover:',
  FR: 'Housse:',
  PL: 'Poszycie:',
  DE: 'Bezug:',
};

export const armrestTitle = {
  EN: 'Armrests:',
  FR: 'Accoudoirs:',
  PL: 'Podłokietniki:',
  DE: 'Armlehnen:',
};

export const accessoriesTitle = {
  EN: 'Accessories:',
  FR: 'Accessoires:',
  PL: 'Akcesoria:',
  DE: 'Accessoires:',
};

export const copyConfig = {
  EN: 'Copy Configuration',
  FR: 'Dupliquer la configuration',
  PL: 'Skopiuj konfigurację',
  DE: 'Konfiguration kopieren',
};

export const loadMore = {
  EN: 'LOAD MORE',
  FR: 'AFFICHER LA SUITE',
  PL: 'WCZYTAJ WIĘCEJ',
  DE: 'MEHR LADEN',
};

export const removeMessage = {
  EN: ' removed from ',
  FR: ' retiré(s) ',
  PL: ' usunięte z ',
  DE: ' entfernt aus ',
};

export const addMessage = {
  EN: ` added to `,
  FR: ` ajouté à `,
  PL: ` dodane do `,
  DE: ` hinzugefügt zu `,
};

export const favouriteLink = {
  EN: 'MY&nbsp;FAVOURITES',
  FR: 'MES&nbsp;FAVORIS',
  PL: 'ULUBIONYCH',
  DE: 'MEINE&nbsp;FAVORITEN',
};

export const accessoriesSectionTitle = {
  EN: 'Accessories for this model',
  FR: 'Accessoires pour ce modèle',
  PL: 'Akcesoria do tego modelu',
  DE: 'Zubehör für dieses Modell',
};

export const searchPageTitle = {
  EN: 'Looking for something specific?',
  FR: 'Vous recherchez un modèle précis?',
  PL: 'Szukasz czegoś konkretnego?',
  DE: 'Suchen Sie etwas Bestimmtes?',
};

export const searchNoResultMessage = {
  EN: `We couldn’t find any matches for „<search>”.
    Double check your search for any typos or spelling errors - or try a different search term.`,
  FR: `Nous n'avons trouvé aucune correspondance pour „<search>”.
    Vérifiez votre recherche pour toute faute de frappe ou d'orthographe - ou essayez un terme de recherche différent.`,
  PL: `Nie znaleźliśmy żadnych wyników dla „<search>”.
    Sprawdź swoje wyszukiwanie pod kątem literówek lub błędów ortograficznych - lub spróbuj innego terminu wyszukiwania.`,
  DE: `Wir konnten keine Übereinstimmungen für „<search>” finden.
    Überprüfen Sie Ihre Suche auf Tippfehler oder Rechtschreibfehler - oder versuchen Sie es mit einem anderen Suchbegriff.`,
};

export const contatcButton = {
  EN: 'Contact us',
  FR: 'Nous contacter',
  PL: 'Kontakt',
  DE: 'Kontaktiere uns',
};

export const findRetailers = {
  EN: 'Find retailers',
  FR: 'Localisez les points de vente',
  PL: 'Znajdź salony sprzedaży',
  DE: 'Händler finden',
};

export const inThisImage = {
  EN: 'In this image',
  FR: 'Sur cette image',
  PL: 'Na tym zdjęciu',
  DE: 'Auf diesem Bild',
};

export const selectLanguage = {
  EN: 'Select your country',
  FR: 'Sélectionnez votre pays',
  PL: 'Wybierz kraj',
  DE: 'Wählen Sie Ihr Land',
};

export const upcommingEvents = {
  EN: 'Upcoming events',
  FR: 'Événements à venir',
  PL: 'Nadchodzące wydarzenia',
  DE: 'Fachmessen',
};

export const ourShowrooms = {
  EN: 'Our showrooms',
  FR: 'Nos showrooms',
  PL: 'Nasze salony',
  DE: 'Unsere Showrooms',
};

export const eventsTitle = {
  EN: 'Events',
  FR: 'Événements',
  PL: 'Nadchodzące wydarzenia',
  DE: 'Veranstaltungen',
};

export const showrooms = {
  EN: 'Showrooms',
  FR: 'Showrooms',
  PL: 'Salony',
  DE: 'Showrooms',
};

export const showNoResultMessage = {
  EN: 'No results found',
  FR: 'Aucun résultat trouvé',
  PL: 'Nie znaleziono wyników',
  DE: 'Keine Ergebnisse gefunden',
};

export const seeMore = {
  EN: 'See more',
  FR: 'Voir plus',
  PL: 'Zobacz więcej',
  DE: 'Mehr sehen',
};

export const downloadFairFolder = {
  EN: 'Download fair folder',
  FR: 'Télécharger le dossier de la foire',
  PL: 'Pobierz folder targowy',
  DE: 'Messeordner herunterladen',
};

export const download = {
  EN: 'Download',
  FR: 'Télécharger',
  PL: 'Pobierz',
  DE: 'Herunterladen',
};

export const view = {
  EN: 'View',
  FR: 'Voir',
  PL: 'Zobacz',
  DE: 'Ansehen',
};

export const discoverUpcominEvents = {
  EN: 'Discover our upcoming events',
  FR: 'Découvrez nos prochains événements',
  PL: 'Odkryj nasze nadchodzące wydarzenia',
  DE: 'Entdecken Sie unsere kommenden Veranstaltungen',
};

export const seeFull = {
  EN: 'See full event list',
  FR: 'Voir la liste complète des événements',
  PL: 'Zobacz pełną listę wydarzeń',
  DE: 'Vollständige Veranstaltungsliste anzeigen',
};
